// src/MediaPlayer.tsx

import React, { useRef, useState, useEffect } from 'react';
import Hls from 'hls.js';
import './MediaPlayer.css';

interface MediaPlayerProps {
  audioSrc: string;
}

const MediaPlayer: React.FC<MediaPlayerProps> = ({ audioSrc }) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      const audio = audioRef.current;
      setCurrentTime(audio.currentTime);
      setDuration(audio.duration);
      setProgress((audio.currentTime / audio.duration) * 100);
    }
  };

  const handleSeekBarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (audioRef.current) {
      const audio = audioRef.current;
      audio.currentTime = (Number(e.target.value) / 100) * audio.duration;
    }
  };

  useEffect(() => {
    const audio = audioRef.current;

    if (audio) {
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(audioSrc);
        hls.attachMedia(audio);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          setDuration(audio.duration);
        });
      } else if (audio.canPlayType('application/vnd.apple.mpegurl')) {
        audio.src = audioSrc;
      } else {
        console.error("HLS not supported on this platform.");
        return;
      }

      audio.addEventListener('timeupdate', handleTimeUpdate);
      return () => audio.removeEventListener('timeupdate', handleTimeUpdate);
    }
  }, [audioSrc]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };


  return (
    <div className="media-player">
      <audio ref={audioRef} />
      <div className="controls">
        <button onClick={togglePlayPause}>{isPlaying ? 'Pause' : 'Play'}</button>
        <input
          type="range"
          value={progress}
          onChange={handleSeekBarChange}
          min="0"
          max="100"
        />
        <div>
        {formatTime(currentTime)} /{' '}
          {duration ? formatTime(duration) : '0:00'}
        </div>
      </div>
    </div>
  );
};

export default MediaPlayer;
